import { template as template_babd660e2a134908a0a7b29992023e08 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_babd660e2a134908a0a7b29992023e08(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
