import { template as template_686e53edbe774546b2c53762a9f8037c } from "@ember/template-compiler";
const SidebarSectionMessage = template_686e53edbe774546b2c53762a9f8037c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
