import { template as template_10dbf02e51bf48409dc3b05998911b01 } from "@ember/template-compiler";
const FKText = template_10dbf02e51bf48409dc3b05998911b01(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
